import React from 'react'

const MyLoadingAnimation = () => {
  return (
    <div className="loading-animation-11">
    <div className="spinner-11"></div>
  </div>  
  )
}

export default MyLoadingAnimation