import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import { Bars } from "react-loader-spinner";
import { useAuth } from "../components/AuthContext";
import { decryptData } from "../hooks/encryptionUtils";
import Breadcrumb from "../components/Breadcrumb";
import SideDefault from "../components/SideDefault";
import { toast } from "react-toastify";
import axios from "axios";
import profile from '../assets/images/no-user.svg';
import MainHeader from "../components/MainHeader";
import TopPagination from "../components/TopPagination";
const Accounts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const param_acc_name = useParams();
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [viewId, setViewId] = useState('');
  const user = localStorage.getItem("skyfi");
  const tk = JSON.parse(user);

  const [fetchacc, setFetchacc] = useState(0);
  const [showAcnts, setShowAcnts] = useState(false);
  const [customerGroup, setCustomerGroup] = useState(false);

  let busines_id = param_acc_name?.name?.split("_")[0];
  const { userRole } = useAuth();

  useEffect(() => {
    if (param_acc_name) {
      setFetchacc(fetchacc + 1);
    }
  }, [param_acc_name]);
  useEffect(() => {
    if (busines_id) {
      setShowAcnts(false);
    }

  }, []);
  const fetchUrl = param_acc_name?.name
    ? `${endPoints.GET_BUSINESS_ACCOUNT}/${busines_id}`
    : `${endPoints.ALL_ACCOUNTS}`;

  const { data, dataLoading } = useFetch(fetchUrl, fetchacc);

  const showAccountDetails = (id, label, item) => {
    if (item?.tp_account_number) {
      let slug_label = id + "_" + label?.trim()?.replaceAll(" ", "-");
      navigate(`/${param_acc_name?.name ? param_acc_name?.name : item?.business_uuid}/accounts/${slug_label}`);
    } else {
      toast.error("Account is under review. Please try again later.");
    }
  };

  const handleCreateAccounts = async (name, buss_id, item) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}${endPoints.ME}`
      const resp = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${tk?.access_token}`
        }
      });
      setLoading(false);
      if (resp.status == 200) {
        //     if (resp?.data?.disclosure_agreement === false) {
        //       const consentVerify = JSON.stringify(resp?.data?.disclosure_agreement);
        //       localStorage.setItem("consentVerify", consentVerify);
        //     } else {
        //       localStorage.removeItem("consentVerify");
        //     }
        // const verify = localStorage.getItem("consentVerify");
        // const verifyPolicy = JSON.parse(verify);
        if (resp?.data?.disclosure_agreement === false) {
          navigate(
            `/${name}/ach-origination`,
            {
              state: {
                businessId: buss_id,
                businessData: item,
                verifyPolicy: resp?.data?.disclosure_agreement,
              },
            }
          );
        } else {
          navigate(
            `/${name}/adding-account`,
            {
              state: {
                businessId: buss_id,
                businessData: item,
                verifyPolicy: resp?.data?.disclosure_agreement,
                flag: true
              },
            }
          );
        }
      } else {
        setLoading(false);
        toast.error(resp?.data?.error || "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);

    }
  };

  const shortName = (name) => {
    name = name?.trim()?.split(" ");
    // let res =
    //   name?.length > 1
    //     ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase()
    //     : name[0][0]?.toUpperCase();
    let res = name[0][0]?.toUpperCase();

    return res;
  };

  useEffect(() => {
    let userData = localStorage.getItem("user");
    if (userData) {
      try {
        const decrypted = decryptData(userData);
        if (data?.account?.length > 0 && decrypted?.customerGroup == 2) {
          setCustomerGroup(false);
        } else setCustomerGroup(true);
        // setUserRole(decrypted);
      } catch (error) {
        console.error("Decryption error:", error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [data]);
  // breadcrumb routes
  const routes = [
    { name: 'Home', path: '/' },
    { name: `${window.location.pathname === "/accounts" ? "Accounts" : "Business Accounts"}`, path: '' },
  ];

  const handleView = (e, val, item) => {
    e.stopPropagation();
    setViewId(item.id);
    setView(val);
  }

  return (
    <>
      <MainHeader />
      {!dataLoading && (
        <div className="s-layout">
          {/* Sidebar */}
          <SideDefault />
          <main className="s-layout__content busi-secn">
            <div className="main-page">
              <div className="">
                <div className="card plan-list no-bg">
                  {/* <Breadcrumb routes={routes} /> */}
                  <div className="card-header d-sm-flex flex-wrap align-items-center d-block pb-4 border-0 hdr-bdr-b">
                    <div className="me-auto">
                      <h4 className="text-black fs-26 mb-0 d-flex align-items-center">
                        {!busines_id ? "Accounts" : "Business Accounts"}
                      </h4>
                    </div>
                    {/* <TopPagination /> */}
                    <div className="d-flex gap-2 justify-content-between justify-content-sm-end acounts-bts">
                      {busines_id && (userRole == "admin" || userRole == "user") && <div className="edit-account  cursor-pointer" onClick={() => { navigate(`/update-business/${param_acc_name?.name?.split("_")[0]}`) }}><a ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1l1-4l9.5-9.5z" />
                        </g>
                      </svg> Edit</a> </div>}
                      {busines_id &&
                        userRole != "admin" &&
                        userRole != "edit_account" &&
                        userRole != "view_account" &&
                        userRole != "card_only" &&
                        customerGroup && (
                          <div className=" create-account">
                            <a className="cursor-pointer"
                              onClick={() => handleCreateAccounts(param_acc_name?.name, busines_id, location?.state?.item)}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2v-6Z" />
                              </svg>Create New Account
                            </a>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="card-body  pt-2">
                    <div className="row">
                      <div className="col-md-12 mt-4 busi-tbl">
                        <table>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Account Number</th>
                            <th>Total Balance</th>
                            <th></th>
                          </tr>
                          {/* business accounts */}
                          {!showAcnts &&
                            data?.account?.length > 0 &&
                            data?.account?.map((item) => {
                              return (
                                <tr
                                  className="cursor-pointer"
                                  onClick={() => {
                                    showAccountDetails(
                                      item?.uuid,
                                      item?.label,
                                      item
                                    );
                                  }}
                                  key={item.id}
                                >
                                  <td>
                                    <div class="list-icons">
                                      {shortName(item?.label)}
                                    </div>
                                  </td>
                                  <td>{item?.label}</td>
                                  <td style={{ width: '20%' }}>
                                    {item?.id === viewId && view ? <> {item?.tp_account_number}</> : <> xxxx-{item?.tp_account_number?.slice(
                                      -4
                                    )}
                                    </>}
                                    {item?.id === viewId && view ?
                                      <i onClick={(e) => handleView(e, false, item)} className="fa fa-eye" aria-hidden="true"></i> :
                                      <i onClick={(e) => handleView(e, true, item)} className="fa fa-eye-slash" aria-hidden="true"></i>}
                                  </td>
                                  <td>${item?.remaining_balance ? Number(item?.remaining_balance).toLocaleString() : 0.00}</td>
                                  <td><div class="dropdown"><button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"></path></svg></button></div></td>
                                </tr>
                              );
                            })}

                          {/* all accounts */}
                          {!showAcnts &&
                            data?.data?.length > 0 &&
                            data?.data?.map((item) => {
                              return (
                                <tr className="cursor-pointer" key={item.id} onClick={() => {
                                  showAccountDetails(
                                    item.attributes.uuid,
                                    item?.attributes?.label,
                                    item?.attributes
                                  );
                                }}>
                                  <td>
                                    <div class="list-icons">
                                      {shortName(item?.attributes?.label)}
                                    </div>
                                  </td>
                                  <td>{item?.attributes?.label}</td>
                                  <td style={{ width: '20%' }}>
                                    {item?.id === viewId && view ? <> {item?.attributes?.tp_account_number}</> : <> xxxx-{item?.attributes?.tp_account_number?.slice(
                                      -4
                                    )}
                                    </>}
                                    {item?.id === viewId && view ?
                                      <i onClick={(e) => handleView(e, false, item)} className="fa fa-eye" aria-hidden="true"></i> :
                                      <i onClick={(e) => handleView(e, true, item)} className="fa fa-eye-slash" aria-hidden="true"></i>}
                                  </td>
                                  <td>${Number(item?.attributes?.remaining_balance).toLocaleString('en-US')}</td>
                                  <td><div class="dropdown"><button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"></path></svg></button></div></td>
                                </tr>
                              );
                            })}

                          {/* <tr>
                            <td><div class="list-icons ">P</div></td>
                            <td>Pawan Rathore</td>
                            <td>xxxx-7773 <i class="fa fa-eye" aria-hidden="true"></i>
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </td>
                            <td>$985.25.00</td>
                            <td><div class="dropdown"><button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"></path></svg></button></div></td>
                          </tr>
                          <tr>
                            <td><div class="list-icons ">P</div></td>
                            <td>Pawan Rathore</td>
                            <td>xxxx-7773 <i class="fa fa-eye" aria-hidden="true"></i>
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </td>
                            <td>$985.25.00</td>
                            <td><div class="dropdown"><button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"></path></svg></button></div></td>
                          </tr>
                          <tr>
                            <td><div class="list-icons ">P</div></td>
                            <td>Pawan Rathore</td>
                            <td>xxxx-7773 <i class="fa fa-eye" aria-hidden="true"></i>
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </td>
                            <td>$985.25.00</td>
                            <td><div class="dropdown"><button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm0 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"></path></svg></button></div></td>
                          </tr> */}

                        </table>
                      </div>
                    </div>
                  </div>


                  {/* <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                    <div className="me-auto pe-3 mb-3">
                      <h4 className="text-black fs-26 mb-0 d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px", color: "38AFFF" }} width="30" height="40" viewBox="0 0 16 16">
                          <path fill="currentColor" fill-rule="evenodd" d="M8 14.5a6.47 6.47 0 0 0 3.25-.87V11.5A2.25 2.25 0 0 0 9 9.25H7a2.25 2.25 0 0 0-2.25 2.25v2.13A6.47 6.47 0 0 0 8 14.5Zm4.75-3v.937a6.5 6.5 0 1 0-9.5 0V11.5a3.752 3.752 0 0 1 2.486-3.532a3 3 0 1 1 4.528 0A3.752 3.752 0 0 1 12.75 11.5ZM8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16ZM9.5 6a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Z" clip-rule="evenodd" />
                        </svg>
                        {!busines_id ? "Accounts" : "Business Accounts"}
                      </h4>
                    </div>
                    <div className="d-flex gap-2 justify-content-between justify-content-sm-end">
                      {/* {busines_id && <div className="edit-account  cursor-pointer" onClick={() => { navigate(`/register/rollfi/${busines_id}`) }}><a >Sign up for RollFI</a> </div>} */}
                  {/* {busines_id && (userRole == "admin" || userRole == "user") && <div className="edit-account  cursor-pointer" onClick={() => { navigate(`/update-business/${param_acc_name?.name?.split("_")[0]}`) }}><a >Edit</a> </div>}
                      {busines_id &&
                        userRole != "admin" &&
                        userRole != "edit_account" &&
                        userRole != "view_account" &&
                        userRole != "card_only" &&
                        customerGroup && (
                          <div className=" create-account">
                            <a className="cursor-pointer"
                              onClick={() => handleCreateAccounts(param_acc_name?.name, busines_id, location?.state?.item)}
                            >
                              Create New Account
                            </a>
                          </div>
                        )}
                    </div>
                  </div>  */}
                  <div className="card-body  pt-2">
                    <div className="row">
                      {/* all accounts */}
                      {/* {!showAcnts &&
                        data?.data?.length > 0 &&
                        data?.data?.map((item) => {
                          return (
                            <div
                              className="col-md-4 col-sm-6"
                              key={item.id}
                            >
                              <div
                                className="flex-wrap align-items-center card-box cursor-pointer"
                                onClick={() => {
                                  showAccountDetails(
                                    item.attributes.uuid,
                                    item?.attributes?.label,
                                    item?.attributes
                                  );
                                }}
                              >
                                <div className="col-xl-12 align-items-center">
                                  <div className="list-icons">
                                    {shortName(item?.attributes?.label)}
                                  </div>
                                  <div className="info mb-3 mt-3">
                                    <h4 className="fs-20 mb-0 text-black text-capitalize">
                                      {item.attributes.label}
                                    </h4>
                                    <span>
                                      xxxx-
                                      {item?.attributes?.tp_account_number?.slice(
                                        -4
                                      )}
                                    </span>
                                    <h5 className="mt-2">${Number(item?.attributes?.remaining_balance).toLocaleString('en-US')}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })} */}
                      {data?.data?.length == 0 && (
                        <p className="">No Data Found</p>
                      )}
                      {/* business accounts */}
                      {/* {!showAcnts &&
                        data?.account?.length > 0 &&
                        data?.account?.map((item) => {
                          return (
                            <div
                              className="col-md-4 col-sm-6"
                              key={item.id}
                            >
                              <div
                                className="flex-wrap align-items-center card-box cursor-pointer"
                                onClick={() => {
                                  showAccountDetails(
                                    item?.uuid,
                                    item?.label,
                                    item
                                  );
                                }}
                              >
                                <div className="col-xl-12 align-items-center">
                                  <div className="list-icons ">
                                    {shortName(item?.label)}
                                  </div>
                                  <div className="info mb-3 mt-3">
                                    <h4 className="fs-20 mb-0 text-black text-capitalize">
                                      {item?.label}
                                    </h4>
                                    <span>
                                      xxxx-
                                      {item?.tp_account_number?.slice(
                                        -4
                                      )}
                                    </span>
                                    <h5 className="mt-2">${item?.remaining_balance ? Number(item?.remaining_balance).toLocaleString() : 0.00}</h5>

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })} */}
                      {data?.account?.length == 0 && (
                        <p className="">No Data Found</p>
                      )}
                      <div className="col-md-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={dataLoading || loading}
      />
    </>
  );
};

export default Accounts;
