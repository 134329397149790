import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import SideDefault from '../components/SideDefault';
import MainHeader from '../components/MainHeader';
import axios from 'axios';
import { toast } from 'react-toastify';
import GoogleMapsComponent from '../components/GoogleMapsComponent';
import { geocodeByAddress } from 'react-places-autocomplete';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';
const AddContact = () => {
    const user = localStorage.getItem("skyfi");
    const tk = JSON.parse(user);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [cntData, setCntData] = useState({
        last_name: "",
        first_name: "",
        email: "",
        account_type: "",
        bank_name: "",
    });
    const [contactAddress, setContactAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        regionCode: "",
        zipCode: "",
    });
    const [contactAddressWire, setContactAddressWire] = useState({
        address_wire1: "",
        address_wire2: "",
        city_wire: "",
        regionCode_wire: "",
        zipCode_wire: "",
        country_wire: "",
    });
    const [addressInput, setAddressInput] = useState('');
    const [addressInputWire, setAddressInputWire] = useState('');
    const [phone, setPhone] = useState("");
    const [account_id, setAccount_id] = useState('')
    const [confirm_account_id, setConfirm_account_id] = useState('')
    const [routing_number, setRouting_number] = useState("")
    const [wire_routing_number, setWire_routing_number] = useState("")
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [aCH, setACH] = useState(true);
    const [wire, setWire] = useState(false);

    const [error_routing_number, setError_routing_number] = useState('');

    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const handleChange = (name, value) => {
        if ((name === "first_name" || name === "last_name" || name === "display_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        } else {
            setCntData({ ...cntData, [name]: value });
        }

        if (name === "email") {
            setCntData({ ...cntData, email: value });
            if (value && !isValidEmail(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Invalid email format"
                }));
                return;
            }
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));

    };

    const handleAddressChange = (e) => {
        const { value, name } = e.target;
        setContactAddress({ ...contactAddress, [name]: value });

    };
    const handleAddressWireChange = (e) => {
        const { value, name } = e.target;
        setContactAddressWire({ ...contactAddress, [name]: value });

    };

    const handlePhoneChange = (value) => {
        if (value && value?.length != 12) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: "Phone number should be 10 digits"
            }));
            return;
        }
        else {
            setPhone(value);
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            phone: ""
        }));
    };

    const handleAccountChange = (e) => {
        const { value } = e.target;
        if (value && !isNumeric(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                account_id: "number digit is required"
            }));
            return;
        }
        else {
            setAccount_id(value);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            account_id: ""
        }));
    };
    const handleConfirmAccountChange = (e) => {
        const { value } = e.target;
        if (value && !isNumeric(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirm_account_id: "number digit is required"
            }));
            return;
        }
        else {
            setConfirm_account_id(value);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_account_id: ""
        }));
    };

    const handleRoutingChange = (value) => {
        if (value && !isNumeric(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                routing_number: "number digit is required"
            }));
            return;
        }
        else {
            setRouting_number(value);
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            routing_number: ""
        }));

    }
    const handleWireRoutingChange = (value) => {
        if (value && !isNumeric(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                wire_routing_number: "number digit is required"
            }));
            return;
        }
        else {
            setWire_routing_number(value);
            handleRounting(value);
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            wire_routing_number: ""
        }));
    };

    const handleRounting = async (value) => {
        if (value?.length < 9) {
            return;
        }
        if (value) {
            try {
                setLoading(true);
                let res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/v3/routing_number/${value}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${tk.access_token}`
                        }
                    }
                );
                setLoading(false);

                if (res?.data) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        wire_routing_number: '',
                    }))
                    setWire_routing_number(res?.data?.routing_number);
                    setCntData(prev => ({ ...prev, bank_name: res?.data?.bank_name }));
                    setContactAddressWire(prev => ({
                        ...prev,
                        regionCode_wire: res?.data?.state,
                        city_wire: res?.data?.city,
                    }));
                    setError_routing_number('');
                }
            } catch (error) {
                setLoading(false);
                console.log("error", error);
                toast.error(error?.response?.data?.error);
                setError_routing_number(error?.response?.data?.error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    wire_routing_number: error?.response?.data?.error
                }))
            }
        }
    }

    const handleSelect = async (address) => {
        setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;

            const newAddress = {
                address1: '',
                address2: '',
                city: '',
                regionCode: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('street_number')) {
                    newAddress.address1 = component.long_name;
                }
                if (types.includes('route')) {
                    newAddress.address1 = newAddress.address1 + " " + component.long_name;
                }
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.regionCode = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
            });

            setContactAddress(newAddress);
            setErrors(prev => ({
                ...prev,
                address1: newAddress.address1 ? "" : errors.address1,
                city: newAddress.city ? "" : errors.city,
                regionCode: newAddress.regionCode ? "" : errors.regionCode,
                zipCode: newAddress.zipCode ? "" : errors.zipCode,
            }));
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };
    const handleSelectWire = async (address) => {
        setAddressInputWire(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;

            const newAddress = {
                address_wire1: '',
                address_wire2: '',
                city_wire: '',
                regionCode_wire: '',
                zipCode_wire: '',
                country_wire: "",
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('street_number')) {
                    newAddress.address_wire1 = component.long_name;
                }
                if (types.includes('route')) {
                    newAddress.address_wire1 = newAddress.address_wire1 + " " + component.long_name;
                }
                if (types.includes('locality')) {
                    newAddress.city_wire = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.regionCode_wire = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode_wire = component.long_name;
                }
                if (types.includes('country')) {
                    newAddress.country_wire = component.short_name;
                }
            });

            setContactAddressWire(newAddress);
            setErrors((prevErrors) => ({
                ...prevErrors,
                address_wire1: newAddress.address_wire1 ? "" : errors.address_wire1,
                city_wire: newAddress.city_wire ? "" : errors.city_wire,
                regionCode_wire: newAddress.regionCode_wire ? "" : errors.regionCode_wire,
                zipCode_wire: newAddress.zipCode_wire ? "" : errors.zipCode_wire,
                country_wire: newAddress.country_wire ? "" : errors.country_wire,
            }));
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const validate = () => {
        let validationErrors = { ...errors };
        if (!cntData.display_name) {
            validationErrors.display_name = "Display Name is required";
        }
        if (!cntData.first_name) {
            validationErrors.first_name = "First Name is required";
        }

        // if (!cntData.last_name) {
        //     validationErrors.last_name = "Last Name is required";
        // }

        // if (!cntData.email) {
        //     validationErrors.email = "Email is required";
        // } else
        if (cntData.email && !/\S+@\S+\.\S+/.test(cntData.email)) {
            validationErrors.email = "Email address is invalid";
        }

        if (!phone) {
            validationErrors.phone = "Phone is required";
        }
        if (!cntData?.account_type) {
            validationErrors.account_type = "Account Type is required";
        }
        if (!account_id) {
            validationErrors.account_id = "Account number is required";
        } else if (account_id?.length >= 17) {
            validationErrors.account_id = "Account number should be less than 16 digits";
        }

        if (!confirm_account_id) {
            validationErrors.confirm_account_id = "Confirm Account number is required";
        } else if (confirm_account_id?.length >= 17) {
            validationErrors.confirm_account_id = "Confirm Account number should be less than 16 digits";
        }

        if (account_id !== confirm_account_id) {
            validationErrors.confirm_account_id = "Account number and Confirm Account number should be same";
        }

        if (!routing_number) {
            validationErrors.routing_number = "Routing number is required";
        } else if (routing_number?.length != 9) {
            validationErrors.routing_number = "Routing number should be 9 digits";
        }

        if (!contactAddress?.address1) {
            validationErrors.address1 = 'Address 1 is required';
        }
        if (!contactAddress?.city) {
            validationErrors.city = 'City is required';
        }
        if (!contactAddress?.regionCode) {
            validationErrors.regionCode = 'State is required';
        }
        if (!contactAddress?.zipCode) {
            validationErrors.zipCode = 'Postal Code is required';
        }
        if (wire && !wire_routing_number) {
            validationErrors.wire_routing_number = "Wire Routing number is required";
        } else if (wire && wire_routing_number?.length != 9) {
            validationErrors.wire_routing_number = "Wire Routing number should be 9 digits";
        }

        if (wire && !contactAddressWire?.address_wire1) {
            validationErrors.address_wire1 = "Address 1 is required"
        }
        if (wire && !contactAddressWire?.city_wire) {
            validationErrors.city_wire = "City is required"
        }
        if (wire && !contactAddressWire?.regionCode_wire) {
            validationErrors.regionCode_wire = "State is required"
        }
        if (wire && !contactAddressWire?.zipCode_wire) {
            validationErrors.zipCode_wire = "Postal Code is required"
        }
        if (wire && !contactAddressWire?.country_wire) {
            validationErrors.country_wire = "Country is required"
        }
        if (wire && !cntData?.bank_name) {
            validationErrors.bank_name = "Bank Name is required"
        }

        Object.keys(validationErrors).forEach(key => {
            if (validationErrors[key] === '') {
                delete validationErrors[key];
            }
        });
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (error_routing_number) {
            toast.error("Validate routing number is required");
            return;
        }
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        Object.keys(errors).forEach(key => {
            if (errors[key] === '') {
                delete errors[key];
            }
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        } else {
            // let updatedCntData = { ...cntData, phone: phone?.slice(2), account_id, routing_number, wire_routing_number };
            if (aCH && !wire) {

                // navigate(`/contact-address/${params?.acc_id}`, { state: { data: updatedCntData, uuid: location?.state?.uuid } });
                try {
                    setLoading(true);
                    let contact = {
                        phone: phone?.slice(2),
                        account_id: params?.acc_id?.split("_")[0],
                        address_type: 1,
                        email: cntData?.email,
                        display_name: cntData?.display_name,
                        last_name: cntData?.last_name,
                        first_name: cntData?.first_name,
                        address_1: contactAddress.address1,
                        address_2: contactAddress.address2,
                        city: contactAddress.city,
                        region_code: contactAddress.regionCode,
                        zip_code: contactAddress.zipCode,
                        country: "US",
                        account_type: cntData?.account_type,
                        account_number: account_id,
                        routing_number: routing_number,
                    }

                    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}`, contact,
                        {
                            headers: {
                                'Authorization': `Bearer ${tk.access_token}`
                            }
                        }
                    );
                    if (resp.status == 200) {

                        navigate(`/view-contacts/${params?.acc_id}`)
                        toast.success("contact Created");
                        setContactAddress({
                            address1: "",
                            address2: "",
                            city: "",
                            regionCode: "",
                            zipCode: "",
                        });
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toast.error(error?.response?.data?.error);
                    console.log("Error:", error);
                }
            } else {
                // wire api call here
                try {
                    setLoading(true);
                    let contact = {
                        phone: phone,
                        account_id: params?.acc_id?.split("_")[0],
                        address_type: 1,
                        email: cntData?.email,
                        display_name: cntData?.display_name,
                        last_name: cntData?.last_name,
                        first_name: cntData?.first_name,
                        address_1: contactAddress.address1,
                        address_2: contactAddress.address2,
                        city: contactAddress.city,
                        region_code: contactAddress.regionCode,
                        zip_code: contactAddress.zipCode,
                        country: "US",
                        account_type: cntData?.account_type,
                        account_number: account_id,
                        routing_number: routing_number,
                        wire_routing_number: wire_routing_number,
                        bank_name: cntData?.bank_name,
                    }

                    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}`, contact,
                        {
                            headers: {
                                'Authorization': `Bearer ${tk.access_token}`
                            }
                        }
                    );

                    if (resp.status == 200) {
                        toast.success("contact Created");
                        let wireContact = {
                            account_number: account_id,
                            wire_routing_number: wire_routing_number,
                            bank_name: cntData?.bank_name,
                            bank_address: {
                                street_line_1: contactAddressWire?.address_wire1,
                                street_line_2: contactAddressWire?.address_wire2,
                                city: contactAddressWire?.city_wire,
                                state: contactAddressWire?.regionCode_wire,
                                postal_code: contactAddressWire?.zipCode_wire,
                                country: contactAddressWire?.country_wire,
                            },

                        }
                        try {
                            setLoading(true);
                            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}/${resp?.data?.data?.attributes?.uuid}/add-wire`, wireContact,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${tk.access_token}`
                                    }
                                }
                            );
                            setLoading(false);
                            console.log("resp wire", response);
                            toast.success(response?.data?.message);
                            navigate(`/view-contacts/${params?.acc_id}`);

                        } catch (error) {
                            console.log("error", error);
                            setLoading(false);
                            toast.error(error?.response?.data?.error);
                        }

                        // navigate(`/view-contacts/${params?.acc_id}`)
                        // toast.success("contact Created");
                        // setContactAddress({
                        //     address1: "",
                        //     address2: "",
                        //     city: "",
                        //     regionCode: "",
                        //     zipCode: "",
                        // });
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toast.error(error?.response?.data?.error);
                    console.log("Error:", error);
                }
            }
        }
    };

    const handleWire = (e) => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            toast.error("ACH Details field are required");
            return;
        }
        Object.keys(errors).forEach(key => {
            if (errors[key] === '') {
                delete errors[key];
            }
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            toast.error("ACH Details field are required");
            return;
        } else {
            setWire(e.target.checked);
            setACH(!e.target.checked);
        }
    }

    return (
        <>
            <MainHeader />
            {!loading &&
                <div className="s-layout">
                    <SideDefault />
                    <main className="s-layout__content acts-secn ">
                        <div className="main-page">
                            <div className="row">
                                <div className="col-xl-7">
                                    <div className="card login-part">
                                        <div className="card-header d-sm-flex flex-wrap align-items-center d-block pb-4 border-0 hdr-bdr-b">
                                            <div className="d-sm-flex flex-wrap align-items-center w-100 justify-content-between">
                                                <h4 className="text-black fs-26 mb-0 d-flex align-items-center">
                                                    Add Contacts
                                                </h4>
                                                <div className="prev-next-buttons">
                                                    <button className="prev-button" disabled="">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={23}
                                                            height={23}
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="m14 7l-5 5l5 5"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button className="next-button">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={23}
                                                            height={23}
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="1.5"
                                                                d="m10 17l5-5l-5-5"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <div className='d-flex justify-content-between align-items-baseline'>
                                                            <div className='d-flex gap-2 align-items-baseline top-selct'>
                                                                <label for="idRadio">ACH Details</label>
                                                                <input type="radio" name='achWireRadio' id="idRadio" checked={aCH} onChange={(e) => { setACH(e.target.checked); setWire(!e.target.checked) }} />

                                                                <label for="idRadio1">Wire Details</label>
                                                                <input type="radio" name='achWireRadio' id='idRadio1' checked={wire} onChange={(e) => handleWire(e)} />
                                                            </div>
                                                            <button className='save-mtn' onClick={handleSubmit}>Save</button>
                                                        </div>
                                                        <form className="mt-4" onSubmit={handleSubmit}>
                                                            {aCH &&
                                                                <>
                                                                    <h5>Account Holder Details</h5>
                                                                    <div className="form-group">
                                                                        <label for="form1" className="mb-1 form-label">Display Name *</label>
                                                                        <input
                                                                            onChange={(e) => handleChange("display_name", e.target.value)}
                                                                            name='display_name'
                                                                            type="text"
                                                                            id="form1"
                                                                            className="form-control"
                                                                            placeholder="Display Name"
                                                                            value={cntData?.display_name}
                                                                        />
                                                                        {errors.display_name && <small className="text-danger error">{errors.display_name}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form12" className="mb-1 form-label">First Name or Business Name *</label>
                                                                        <input
                                                                            onChange={(e) => handleChange("first_name", e.target.value)}
                                                                            name='first_name'
                                                                            type="text"
                                                                            id="form12"
                                                                            className="form-control"
                                                                            placeholder="First Name"
                                                                            value={cntData?.first_name}
                                                                        />
                                                                        {errors.first_name && <small className="text-danger error">{errors.first_name}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form2" className="mb-1 form-label">Last Name</label>
                                                                        <input
                                                                            onChange={(e) => handleChange("last_name", e.target.value)}
                                                                            name='last_name'
                                                                            type="text"
                                                                            id="form2"
                                                                            className="form-control"
                                                                            placeholder="Last Name"
                                                                            value={cntData?.last_name}
                                                                        />
                                                                        {errors.last_name && <small className="text-danger error">{errors.last_name}</small>}
                                                                    </div>
                                                                    <div className="form-group">

                                                                        <label for="form3" className="mb-1 form-label">Mobile number *</label>
                                                                        <PhoneInput
                                                                            country="US"
                                                                            maxLength={14}
                                                                            id="form3"
                                                                            className="form-control"
                                                                            placeholder="Mobile number"
                                                                            value={phone}
                                                                            onChange={(e) => { handlePhoneChange(e) }} />
                                                                        {errors.phone && <small className="text-danger error">{errors.phone}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form4" className="mb-1 form-label">Email</label>
                                                                        <input
                                                                            name='email'
                                                                            type="text"
                                                                            id="form4"
                                                                            className="form-control"
                                                                            placeholder="Email"
                                                                            value={cntData?.email}
                                                                            onChange={(e) => handleChange('email', e.target.value)}
                                                                        />
                                                                        {errors.email && <small className="text-danger error">{errors.email}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form41" className="mb-1 form-label">Account Type</label>
                                                                        <select
                                                                            className='form-control'
                                                                            name='account_type'
                                                                            id='form41'
                                                                            value={cntData?.account_type}
                                                                            onChange={(e) => handleChange('account_type', e.target.value)}
                                                                        >
                                                                            <option selected="">Select Account</option>
                                                                            <option value={"savings"}>Savings</option>
                                                                            <option value={"checking"}>Checking</option>
                                                                        </select>
                                                                        {errors.account_type && <small className="text-danger error">{errors.account_type}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form6" className="mb-1 form-label">Routing Number *</label>
                                                                        <input
                                                                            name='routing_number'
                                                                            type="text"
                                                                            id="form6"
                                                                            className="form-control"
                                                                            placeholder="Routing Number"
                                                                            maxLength={9}
                                                                            value={routing_number}
                                                                            onChange={(e) => handleRoutingChange(e.target.value)}
                                                                        />
                                                                        {errors.routing_number && <small className="text-danger error">{errors.routing_number}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">Account Number *</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            name='account_id'
                                                                            placeholder="Account Number"
                                                                            maxLength={16}
                                                                            value={account_id}
                                                                            onChange={(e) => handleAccountChange(e)}
                                                                        />
                                                                        {errors.account_id && <small className="text-danger error">{errors.account_id}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">Confirm Account Number *</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            name='confirm_account_id'
                                                                            placeholder="Confirm Account Number"
                                                                            maxLength={16}
                                                                            value={confirm_account_id}
                                                                            onChange={(e) => handleConfirmAccountChange(e)}
                                                                        />
                                                                        {errors.confirm_account_id && <small className="text-danger error">{errors.confirm_account_id}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">Address 1*</label>
                                                                        <GoogleMapsComponent
                                                                            value={addressInput}
                                                                            onChange={setAddressInput}
                                                                            onSelect={handleSelect}
                                                                        />
                                                                        {errors.address1 && <small className="text-danger error">{errors.address1}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">Address 2</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            name="address2"
                                                                            placeholder="Enter Address 2"
                                                                            value={contactAddress.address2}
                                                                            onChange={handleAddressChange}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">City*</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            name="City"
                                                                            placeholder="Enter City"
                                                                            disabled
                                                                            value={contactAddress.city}
                                                                            onChange={handleAddressChange}
                                                                        />
                                                                        {errors.city && <small className="text-danger error">{errors.city}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">State*</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            placeholder="Enter State"
                                                                            value={contactAddress.regionCode}
                                                                            onChange={handleAddressChange}
                                                                        />
                                                                        {errors.regionCode && <small className="text-danger error">{errors.regionCode}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">Postal Code*</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            placeholder="Enter Postal Code"
                                                                            value={contactAddress.zipCode}
                                                                            onChange={handleAddressChange}
                                                                        />
                                                                        {errors.zipCode && <small className="text-danger error">{errors.zipCode}</small>}
                                                                    </div>
                                                                </>}
                                                            {wire &&
                                                                <>
                                                                    <h5>Bank Details</h5>
                                                                    <div className="form-group">
                                                                        <label for="form7" className="mb-1 form-label">Routing Number*</label>
                                                                        <input
                                                                            name='wire_routing_number'
                                                                            type="text"
                                                                            id="form7"
                                                                            className="form-control"
                                                                            placeholder="Enter Routing Number"
                                                                            maxLength={9}
                                                                            value={wire_routing_number}
                                                                            onChange={(e) => handleWireRoutingChange(e.target.value)}
                                                                        />
                                                                        {errors.wire_routing_number && <small className="text-danger error">{errors.wire_routing_number}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form7" className="mb-1 form-label">Bank Name*</label>
                                                                        <input
                                                                            name='bank_name'
                                                                            type="text"
                                                                            id="form7"
                                                                            className="form-control"
                                                                            placeholder="Enter Bank Name"
                                                                            value={cntData?.bank_name}
                                                                            onChange={(e) => handleChange("bank_name", e.target.value)}
                                                                        />
                                                                        {errors.bank_name && <small className="text-danger error">{errors.bank_name}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form7" className="mb-1 form-label">Address 1*</label>
                                                                        <GoogleMapsComponent
                                                                            value={addressInputWire}
                                                                            onChange={setAddressInputWire}
                                                                            onSelect={handleSelectWire}
                                                                        />
                                                                        {errors.address_wire1 && <small className="text-danger error">{errors.address_wire1}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form7" className="mb-1 form-label">Address 2</label>
                                                                        <input
                                                                            name='address_wire2'
                                                                            type="text"
                                                                            id="form7"
                                                                            className="form-control"
                                                                            placeholder="Enter address 2"
                                                                            value={contactAddressWire?.address_wire2}
                                                                            onChange={handleAddressWireChange}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form7" className="mb-1 form-label">City*</label>
                                                                        <input
                                                                            name='city_wire'
                                                                            type="text"
                                                                            id="form7"
                                                                            className="form-control"
                                                                            placeholder="Enter City"
                                                                            value={contactAddressWire?.city_wire}
                                                                            onChange={handleAddressWireChange}
                                                                        />
                                                                        {errors.city_wire && <small className="text-danger error">{errors.city_wire}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form8" className="mb-1 form-label">State*</label>
                                                                        <input
                                                                            name='regionCode_wire'
                                                                            type="text"
                                                                            id="form8"
                                                                            className="form-control"
                                                                            placeholder="Enter State"
                                                                            value={contactAddressWire?.regionCode_wire}
                                                                            onChange={handleAddressWireChange}
                                                                        />
                                                                        {errors.regionCode_wire && <small className="text-danger error">{errors.regionCode_wire}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form5" className="mb-1 form-label">Postal Code*</label>
                                                                        <input
                                                                            type="text"
                                                                            id="form5"
                                                                            className="form-control"
                                                                            placeholder="Enter Postal Code"
                                                                            value={contactAddressWire?.zipCode_wire}
                                                                            onChange={handleAddressWireChange}
                                                                        />
                                                                        {errors.zipCode_wire && <small className="text-danger error">{errors.zipCode_wire}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="form8" className="mb-1 form-label">Country*</label>
                                                                        <input
                                                                            name='country_wire'
                                                                            type="text"
                                                                            id="form8"
                                                                            className="form-control"
                                                                            placeholder="Select Your Country"
                                                                            value={contactAddressWire?.country_wire}
                                                                            onChange={handleAddressWireChange}
                                                                        />
                                                                        {errors.country_wire && <small className="text-danger error">{errors.country_wire}</small>}
                                                                    </div>
                                                                </>}
                                                            {/* <div className="terms">
                                                            <input style={{ marginRight: "10px" }} type="checkbox" id="termsCheckbox" onChange={(e) => setChecked(e.target.checked)} />
                                                            <label for="termsCheckbox">Add Wire Details</label>
                                                        </div>
                                                        <span className='text-muted'>Check this box if you would like to send a wire to this contact</span>
                                                        <div className="create-account mt-5">
                                                            <button>Create</button>
                                                        </div> */}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading}
            />
        </>
    );
};

export default AddContact;
