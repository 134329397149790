import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { useAuth } from '../components/AuthContext';
import SideDefault from '../components/SideDefault';
import Modal from 'react-responsive-modal';
import axios from 'axios';
import { toast } from 'react-toastify';

const ViewAllContacts = () => {
    const location = useLocation();
    // console.log("location",location?.state);
    const { userRole } = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [filterContacts, setFilterContacts] = useState([]);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [view1, setView1] = useState(false);
    const [dp, setDp] = useState(0);
    const [dltId, setDltID] = useState('');
    const [conDetails, setConDetails] = useState({});
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const { data: contacts, dataLoading: contactDataLoading } = useFetch(`${endPoints.CONTACTS}?account_id=${params?.acc_id}`);
    useEffect(() => {
        if (contacts) {
            setDp(0);
            let ft = [...contacts?.data]
            setFilterContacts(ft)
        }

    }, [contacts]);
    const handleChange = (e) => {
        setSearch(e.target.value)
        let fitr = contacts?.data?.filter((item) => {

            if (item?.attributes?.first_name?.toLowerCase()?.includes(e.target.value.toLowerCase()) || item?.attributes?.last_name?.toLowerCase()?.includes(e.target.value.toLowerCase())) {
                return item
            }
        })
        setFilterContacts(fitr)
    }

    const shortName = (name) => {
        name = name?.split(" ");
        if (name) {
            let res = name[0][0]?.toUpperCase();
            return res;
        }
        // let res = name?.length > 1 ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase() : name[0][0]?.toUpperCase()
    };

    const handleDelete = (id) => {
        setDltID(id);
        onOpenModal();
    }

    const handleDeleteContact = async () => {
        try {
            setLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}/${dltId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setLoading(false);
            if (resp?.status == 200) {
                toast.success("Contact deleted");
                setDp(dp + 1);
                onCloseModal();
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }

    const handleClick = (item) => {
        setConDetails(item);
        setView1(false);
        setView(false);
    }

    const handleView = (val) => {
        setView(val);
    }
    const handleView1 = (val) => {
        setView1(val);
    }
    return (
        <>
            <MainHeader />
            <div className="s-layout">
                <SideDefault />
                <main className="s-layout__content acts-cntct">
                    <div className="main-page">
                        <div className="row">
                            <div className="col-xl-7">
                                <div className="card no-bg">
                                    <div className="card-header d-sm-flex flex-wrap align-items-center d-block pb-4 border-0 hdr-bdr-b">
                                        <div className="d-sm-flex flex-wrap align-items-center w-100 justify-content-between">
                                            <h4 className="text-black fs-26 mb-0 d-flex align-items-center">
                                                Add Contacts
                                            </h4>
                                            {/* <div className="prev-next-buttons">
                                                <button className="prev-button" disabled="">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={23}
                                                        height={23}
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="m14 7l-5 5l5 5"
                                                        />
                                                    </svg>
                                                </button>
                                                <button className="next-button">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={23}
                                                        height={23}
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                            d="m10 17l5-5l-5-5"
                                                        />
                                                    </svg>
                                                </button>
                                            </div> */}
                                            {userRole != "view_account" && userRole != "card_only" &&
                                                <button
                                                    className="add-act"
                                                    onClick={() => { navigate(`/add-contact/${params?.acc_id}`, { state: { uuid: params?.acc_id } }) }}>
                                                    + Add Contact
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="act-search">
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            onChange={handleChange}
                                                            placeholder="Search for a contact"
                                                            value={search}
                                                        />

                                                        <button>
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.9854 15.9854L19 19.0001" stroke="#8097B1" stroke-width="1.5" stroke-linecap="round" />
                                                                <circle cx="10.6568" cy="10.6567" r="6.78553" transform="rotate(-45 10.6568 10.6567)" stroke="#8097B1" stroke-width="1.5" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <label>Enter email, name, or phone number</label>
                                                </div>

                                                <ul>
                                                    {filterContacts?.length > 0 && filterContacts?.map((item, ind) => {

                                                        return (
                                                            <li className={conDetails?.attributes?.uuid == item?.attributes?.uuid ? "active" : ""} key={ind} onClick={() => handleClick(item)}>
                                                                <div className="names">
                                                                    <span className="name">{shortName(item?.attributes?.first_name)}</span>
                                                                    <span className="act-name">{item?.attributes?.display_name}</span>
                                                                </div>
                                                                <div className="actn">
                                                                    <button onClick={() => { navigate(`/contact-transfer-money/${params?.acc_id}/${item?.attributes?.uuid}`) }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                                                        <path fill="currentColor" d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414Z" />
                                                                    </svg></button>
                                                                    <button onClick={() => { navigate(`/card-transaction/${params?.acc_id}/${item?.attributes?.uuid}_contact`) }}><svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0.791992 1.2876V10.8199H10" stroke="#02264C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    </button>
                                                                    <button data-bs-toggle="dropdown" aria-expanded="false"><svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="1.5" cy="1.5" r="1.5" fill="#02264C" />
                                                                        <circle cx="1.5" cy="7.5" r="1.5" fill="#02264C" />
                                                                        <circle cx="1.5" cy="13.5" r="1.5" fill="#02264C" />
                                                                    </svg>
                                                                    </button>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item cursor-pointer" onClick={() => handleDelete(item?.attributes?.uuid)}>Delete</a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 cnt-dtl-main">
                                {Object.keys(conDetails).length > 0 &&
                                    <div className="cnt-dtl">
                                        <div className="act">
                                            <div className="ico">{shortName(conDetails?.attributes?.first_name)}</div>
                                            <div className="cnt-nm">{conDetails?.attributes?.display_name}
                                                <a className="cursor-pointer" onClick={() => { navigate(`/update-contact/${params?.acc_id}/${conDetails?.attributes?.uuid}`, { state: { details: conDetails, uuid: params?.acc_id } }) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1l1-4l9.5-9.5z" />
                                                        </g>
                                                    </svg>Edit
                                                </a>
                                            </div>
                                        </div>
                                        <div className="act-dtls">
                                            <div className="row mb-4">
                                                <div className="col-md-5 text-end">
                                                    <strong>Phone</strong>
                                                </div>
                                                <div className="col-md-7 text-start">
                                                    {conDetails?.attributes?.phone || 'N/A'}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-5 text-end">
                                                    <strong>Email</strong>
                                                </div>
                                                <div className="col-md-7 text-start">
                                                    {conDetails?.attributes?.email || 'N/A'}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-5 text-end">
                                                    <strong>Account Number</strong>
                                                </div>
                                                <div className="col-md-7 text-start">
                                                    {!conDetails?.attributes?.account_number ? 'N/A' : view ? conDetails?.attributes?.account_number : '**************'}
                                                    {conDetails?.attributes?.account_number && (view ?
                                                        <i onClick={() => handleView(false)} className="fa fa-eye" aria-hidden="true"></i> :
                                                        <i onClick={() => handleView(true)} className="fa fa-eye-slash" aria-hidden="true"></i>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-5 text-end">
                                                    <strong>Routing Number</strong>
                                                </div>
                                                <div className="col-md-7 text-start">
                                                    {!conDetails?.attributes?.routing_number ? 'N/A' : view1 ? conDetails?.attributes?.routing_number : '*********'}
                                                    {conDetails?.attributes?.routing_number && (view1 ?
                                                        <i onClick={() => handleView1(false)} className="fa fa-eye" aria-hidden="true"></i> :
                                                        <i onClick={() => handleView1(true)} className="fa fa-eye-slash" aria-hidden="true"></i>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </main>
                {/* <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card plan-list">
                                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                        <div className="me-auto pe-3 mb-3">
                                            <h4 className="text-black fs-26 mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
                                                <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
                                            </svg> Contacts</h4>
                                        </div>
                                    </div>
                                    <div className="card-body  pt-2">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="search-contact-row">
                                                    <div className="search-contact">
                                                        <input
                                                            value={search}
                                                            onChange={handleChange}
                                                            type="type"
                                                            className="form-control"
                                                            placeholder="Search Contacts"
                                                        />
                                                        <button type="submit" className="">
                                                            <svg
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.0781 24.8438C10.7511 24.8438 8.47635 24.1537 6.5415 22.8609C4.60665 21.5681 3.09862 19.7305 2.20811 17.5806C1.3176 15.4308 1.0846 13.0651 1.53858 10.7828C1.99256 8.50046 3.11312 6.40403 4.75858 4.75858C6.40403 3.11312 8.50046 1.99256 10.7828 1.53858C13.0651 1.0846 15.4308 1.3176 17.5806 2.20811C19.7305 3.09862 21.5681 4.60665 22.8609 6.5415C24.1537 8.47635 24.8438 10.7511 24.8438 13.0781C24.8438 14.6232 24.5394 16.1532 23.9481 17.5806C23.3569 19.0081 22.4902 20.3051 21.3977 21.3977C20.3051 22.4902 19.0081 23.3569 17.5806 23.9481C16.1532 24.5394 14.6232 24.8438 13.0781 24.8438ZM13.0781 3.67188C11.2239 3.67188 9.41137 4.22171 7.86966 5.25185C6.32795 6.28199 5.12633 7.74616 4.41676 9.45922C3.70719 11.1723 3.52153 13.0573 3.88327 14.8759C4.245 16.6944 5.13789 18.3649 6.449 19.676C7.76012 20.9871 9.43058 21.88 11.2492 22.2417C13.0677 22.6035 14.9527 22.4178 16.6658 21.7083C18.3788 20.9987 19.843 19.7971 20.8732 18.2554C21.9033 16.7136 22.4531 14.9011 22.4531 13.0469C22.4531 10.5605 21.4654 8.17591 19.7073 6.41775C17.9491 4.6596 15.5645 3.67188 13.0781 3.67188Z"
                                                                    fill="white"
                                                                />
                                                                <path
                                                                    d="M27.5001 28.6719C27.3461 28.6727 27.1935 28.6426 27.0513 28.5836C26.9091 28.5246 26.7801 28.4377 26.6719 28.3282L20.2188 21.8751C20.0118 21.6529 19.8991 21.3591 19.9045 21.0555C19.9098 20.7519 20.0328 20.4622 20.2475 20.2475C20.4622 20.0328 20.7519 19.9098 21.0555 19.9045C21.3591 19.8991 21.6529 20.0118 21.8751 20.2188L28.3282 26.6719C28.5476 26.8917 28.6709 27.1895 28.6709 27.5001C28.6709 27.8106 28.5476 28.1085 28.3282 28.3282C28.22 28.4377 28.091 28.5246 27.9488 28.5836C27.8066 28.6426 27.654 28.6727 27.5001 28.6719Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                            Search
                                                        </button>
                                                    </div>
                                                    {userRole != "view_account" && userRole != "card_only" && <button className="add-act" onClick={() => { navigate(`/add-contact/${params?.acc_id}`, { state: { uuid: params?.acc_id } }) }}>
                                                        <svg
                                                            width={22}
                                                            height={19}
                                                            viewBox="0 0 22 19"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M9.73814 11.242H0.474609V8.578H9.73814V0.585938H12.826V8.578H22.0895V11.242H12.826V19.2341H9.73814V11.242Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                        Add
                                                    </button>}
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mt-5">
                                                <ul className="tab-links">

                                                    {filterContacts?.length > 0 && filterContacts?.map((item) => {

                                                        return (
                                                            <li key={item?.id} onClick={() => { navigate(`/contact-details/${params?.acc_id}/${item?.attributes?.uuid}`, { state: { contactId: item?.attributes?.uuid, uuid: params?.acc_id, details: item } }) }}>
                                                                <a className='cursor-pointer' >
                                                                    {item?.attributes?.display_name}
                                                                    <svg
                                                                        width={19}
                                                                        height={19}
                                                                        viewBox="0 0 19 19"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M17.832 9.49976H1.16537"
                                                                            stroke="black"
                                                                            strokeWidth="1.375"
                                                                            strokeMiterlimit={10}
                                                                            strokeLinecap="round"
                                                                        />
                                                                        <path
                                                                            d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                            stroke="black"
                                                                            strokeWidth="1.375"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </li>

                                                        )
                                                    })
                                                    }
                                                    {filterContacts?.length == 0 && <p className="">No Contacts Found</p>}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main> */}
                <Modal open={open} onClose={onCloseModal} center>
                    <div className="p-5">
                        <div className="">
                            <h4>Do you want to delete this Contact</h4>
                            <button className='btn btn-primary' onClick={onCloseModal}>Cancel</button>
                            {/* <button className='btn btn-primary mx-3' > Sue</button> */}
                            <button className='btn btn-primary mx-3' onClick={handleDeleteContact} disabled={loading}> {loading ? "Loading" : "Sure"}</button>
                        </div>

                    </div>
                </Modal>
            </div>
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperclassName="bars"
                visible={contactDataLoading}
            />
        </>
    )
}

export default ViewAllContacts