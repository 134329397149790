import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import Cookies from 'js-cookie';
import { useAuth } from "./AuthContext";
import logo from "../assets/images/logo.png"
import photo from "../assets/images/no-user.svg"
import { decryptData } from "../hooks/encryptionUtils";
import endPoints from "../constants";
import axios from "axios";
import SardineService from "../hooks/SardineService";
import useFetch from "../hooks/useFetch";
import { useGetAccountsQuery } from "../Redux/apiSlice";
import MyLoadingAnimation from "./MyLoadingAnimation";

const Sidebar = () => {
  const { userRole } = useAuth();
  const [activeTab, setActiveTab] = useState("/");
  const navigate = useNavigate();
  const { show, shutdown } = useIntercom();
  const [filterAccount, setFilterAccount] = useState([]);
  const fetchUrl = `${endPoints.ALL_ACCOUNTS}`;
  // const { data, dataLoading } = useFetch(fetchUrl);
  const { data: accounts, isLoading } = useGetAccountsQuery();

  // sardine logout
  function handleSardineLogout() {
    // After logout, reset userIdHash and sessionKey
    SardineService.updateConfig({
      userIdHash: null,
      sessionKey: "NEW_SESSION_KEY", // Generate new session key
      flow: "/login" // Example flow after logout
    });
  };

  const handleSignOut = () => {
    localStorage.clear();
    Cookies.remove('url');
    shutdown();
    handleSardineLogout()
    navigate("/login", { replace: true });

  };
  useEffect(() => {
    setActiveTab(window.location.pathname)
  }, [window.location.pathname])


  const [fixedSidebar, setFixedSidebar] = useState(false);
  let [userDetails, setUserDetails] = useState(null)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 150) {
        setFixedSidebar(true);
      } else {
        setFixedSidebar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const user = localStorage.getItem("skyfi");
  const tk = JSON.parse(user);
  const getUserDetails = async () => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.ME}`,
      {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      }
    );
    if (resp.status == 200) {
      // setUserDetails(resp?.data)
      let user = JSON.stringify(resp?.data)
      localStorage.setItem("user", user)
      // toast.success("Business Created");

    }
  }

  const userd = localStorage.getItem("user");
  useEffect(() => {
    if (userd) {
      try {
        const decrypted = decryptData(userd);
        if (tk && !decrypted) getUserDetails();
        if (decrypted) {
          setUserDetails(decrypted)
        }
        // setUserRole(decrypted);
      } catch (error) {
        console.error('Decryption error:', error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [userd])

  const handleInterCom = () => {
    setActiveTab("main")
    show();
  }

  useEffect(() => {
    setActiveTab("main")
  }, [handleInterCom])
  const [active, setActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setActive(false);
  };

  const shortName = (name) => {
    name = name?.trim()?.split(" ");
    // let res =
    //   name?.length > 1
    //     ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase()
    //     : name[0][0]?.toUpperCase();
    let res = name[0][0]?.toUpperCase();

    return res;
  };

  useEffect(() => {
    let filter = [];
    filter = accounts?.data?.filter((item) => {
      if (item?.attributes?.tp_account_number) {
        return item;
      }
    });
    setFilterAccount(filter);
    setSelectedOption(filter?.length > 0 ? filter[0] : {});
  }, [accounts?.data]);

  return (
    <>
      <input id="slide-sidebar" type="checkbox" role="button" />
      <div className="sidebar">
        <label htmlFor="slide-sidebar"><span><i className="fa fa-bars"></i></span></label>
        <div>
          <div className="dashboard_bar cursor-pointer" onClick={() => { navigate("/") }}>
            {/* <img src={logo} /> */}
          </div>
          <div className={`select-menu active`}>
            <div className="select-btn" onClick={() => setActive(!active)}>
              <span className="sBtn-text">
                <ul className="options">
                  {isLoading && 
                  <MyLoadingAnimation />
                   } 
                  {Object.keys(selectedOption).length > 0 &&
                    <li className="option">
                      <span className="option-text">
                        <div class="acts-icons">{shortName(selectedOption?.attributes?.label)}</div>
                        <div class="acts-text"><span>{selectedOption?.attributes?.label}</span><small>xxxx-{selectedOption?.attributes?.tp_account_number?.slice(
                          -4
                        )}</small></div>
                      </span>
                    </li>
                  }
                </ul>
              </span>
              <i className="bx bx-chevron-down" />
            </div>
            {active && (
              <ul className="options">
                {filterAccount?.length > 0 && filterAccount?.map((item, index) => {
                  if (index < 4)
                    return (
                      <li
                        key={index}
                        className="option"
                        onClick={() => handleOptionClick(item)}
                      >

                        <span className="option-text">
                          <div class="acts-icons">{shortName(item?.attributes?.label)}</div>
                          <div class="acts-text"><span>{item?.attributes?.label}</span><small>xxxx-{item?.attributes?.tp_account_number?.slice(
                            -4
                          )}</small></div>
                        </span>
                      </li>
                    )
                })}
              </ul>
            )}
          </div>

          <ul
            className="nav"
            id="menu"

          >
            {/* Home */}
            <li>
              <NavLink
                to="/" className={`nav-link align-middle busis px-0`} onClick={() => setActiveTab("/")}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20">
                  <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
                </svg>
                <span className="ms-1  d-sm-inline"> Home</span>
              </NavLink>
            </li>

            {/* Users */}
            {userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" &&

              <li className="nav-item">
                <NavLink to="/staff-list" className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/staff-list")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
                    <path fill="currentColor" d="M117.25 157.92a60 60 0 1 0-66.5 0a95.83 95.83 0 0 0-47.22 37.71a8 8 0 1 0 13.4 8.74a80 80 0 0 1 134.14 0a8 8 0 0 0 13.4-8.74a95.83 95.83 0 0 0-47.22-37.71ZM40 108a44 44 0 1 1 44 44a44.05 44.05 0 0 1-44-44Zm210.14 98.7a8 8 0 0 1-11.07-2.33A79.83 79.83 0 0 0 172 168a8 8 0 0 1 0-16a44 44 0 1 0-16.34-84.87a8 8 0 1 1-5.94-14.85a60 60 0 0 1 55.53 105.64a95.83 95.83 0 0 1 47.22 37.71a8 8 0 0 1-2.33 11.07Z" />
                  </svg>
                  <span className="ms-1  d-sm-inline"> Users</span>
                </NavLink>
              </li>}
            {/* Transactions */}
            <li>
              <NavLink
                to="/transactions" className={`nav-link align-middle px-0`} onClick={() => setActiveTab(`/transactions`)}
              >
                {/* <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 21" >
                  <path d="M21 13H1L8.38095 20" stroke="black" stroke-width="1.36" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7 8L27 8L19.619 1" stroke="black" stroke-width="1.36" stroke-linecap="round" stroke-linejoin="round" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-width="2" d="M2 7h18m-4-5l5 5l-5 5m6 5H4m4-5l-5 5l5 5" /></svg>
                <span className="ms-1  d-sm-inline"> Transactions</span>
              </NavLink>
            </li>
            {/* Connected Accounts */}
            <li>
              <NavLink
                to="/external-account" className={`nav-link align-middle px-0`} onClick={() => setActiveTab(`/external-account`)}
              >
                {/* <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 21" >
                  <path d="M21 13H1L8.38095 20" stroke="black" stroke-width="1.36" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7 8L27 8L19.619 1" stroke="black" stroke-width="1.36" stroke-linecap="round" stroke-linejoin="round" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="m12.922 16.587l-3.671 3.671a3.896 3.896 0 0 1-5.504-5.509l-.002.002l3.671-3.671a1.3 1.3 0 0 0-1.837-1.835l.001-.001l-3.671 3.671a6.494 6.494 0 0 0 9.187 9.175l-.003.002l3.671-3.671a1.3 1.3 0 0 0-1.837-1.835l.001-.001zM24.007 6.489A6.494 6.494 0 0 0 12.921 1.9L9.25 5.571a1.3 1.3 0 1 0 1.835 1.837l.001-.001l3.671-3.671a3.896 3.896 0 0 1 5.504 5.509l.002-.002l-3.671 3.671a1.3 1.3 0 1 0 1.835 1.837l.001-.001l3.671-3.671a6.432 6.432 0 0 0 1.908-4.58V6.49z" /><path fill="currentColor" d="M7.412 16.592c.235.235.559.38.918.38s.683-.145.918-.38L16.59 9.25a1.3 1.3 0 0 0-1.837-1.835l.001-.001l-7.342 7.342c-.235.235-.38.559-.38.918s.145.683.38.918z" /></svg>
                <span className="ms-1  d-sm-inline"> Connected Accounts</span>
              </NavLink>
            </li>
            {/* <li>
              <Link
                to="#submenu1"
                data-bs-toggle="collapse"
                className={`nav-link align-middle px-0`} onClick={() => setActiveTab("#submenu1")}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20">
                  <path fill="currentColor" d="M9.993 10.573a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078c.047.367-.226.7-.61.745c-.383.045-.733-.215-.78-.582c-.54-4.19-4.169-7.345-8.57-7.345c-4.425 0-8.101 3.161-8.64 7.345c-.047.367-.397.627-.78.582c-.384-.045-.657-.378-.61-.745c.496-3.844 3.281-6.948 6.975-8.068A6 6 0 0 1 10 0Z" />
                </svg>
                <span className="ms-1  d-sm-inline">
                  Accounts <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </Link>
              <div
                className="collapse nav ms-1 drop-menus"
                id="submenu1"
                data-bs-parent="#menu"
              >
                <ul>
                  {userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" &&

                    <li>
                      <NavLink to={'/accounts'} className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/accounts")}>

                        <span className="">SkyFi Accounts</span>
                        </NavLink>
                      </li>}
                  <li>
                  <NavLink to="/external-account" className={`nav-link align-middle px-0`} onClick={() => setActiveTab("/external-account")}>
                        <span className="">External Accounts</span>
                      </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}



            {/* Payroll */}
            <li>
              <NavLink
                to="/register/rollfi" className={`nav-link align-middle px-0`} onClick={() => setActiveTab(`/register/rollfi`)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 16 16"><path fill="currentColor" d="M14.5 16h-13C.67 16 0 15.33 0 14.5v-12C0 1.67.67 1 1.5 1h13c.83 0 1.5.67 1.5 1.5v12c0 .83-.67 1.5-1.5 1.5ZM1.5 2c-.28 0-.5.22-.5.5v12c0 .28.22.5.5.5h13c.28 0 .5-.22.5-.5v-12c0-.28-.22-.5-.5-.5h-13Z" /><path fill="currentColor" d="M4.5 4c-.28 0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5s.5.22.5.5v3c0 .28-.22.5-.5.5Zm7 0c-.28 0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5s.5.22.5.5v3c0 .28-.22.5-.5.5Zm4 2H.5C.22 6 0 5.78 0 5.5S.22 5 .5 5h15c.28 0 .5.22.5.5s-.22.5-.5.5Z" /></svg>
                <span className="ms-1  d-sm-inline"> Payroll</span>
              </NavLink>
            </li>
            {/* Disclosures */}
            <li>
              <Link
                to="https://skyfichecking.com/disclosures/"
                target="_blank"
                className="nav-link px-0 align-middle"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"><g fill="currentColor"><path d="M6.5 12a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7Zm0 3a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7Z" /><path fill-rule="evenodd" d="M11.185 1H4.5A1.5 1.5 0 0 0 3 2.5v15A1.5 1.5 0 0 0 4.5 19h11a1.5 1.5 0 0 0 1.5-1.5V7.202a1.5 1.5 0 0 0-.395-1.014l-4.314-4.702A1.5 1.5 0 0 0 11.185 1ZM4 2.5a.5.5 0 0 1 .5-.5h6.685a.5.5 0 0 1 .369.162l4.314 4.702a.5.5 0 0 1 .132.338V17.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-15Z" clip-rule="evenodd" /><path d="M11 7h5.5a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 1 0V7Z" /></g></svg>
                <span className="ms-1  d-sm-inline"> Disclosures</span>
              </Link>
            </li>
            {/* <li>
              <Link
                // to=""
                // target="_blank"
                className="nav-link px-0 align-middle"
              >
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 2048 2048"><path fill="currentColor" d="M1783 988v18q0 9 1 18v18q0 9-1 18l259 161l-159 383l-297-68q-24 26-50 50l68 297l-383 159l-161-259h-18q-9 0-18 1h-18q-9 0-18-1l-161 259l-383-159l68-297q-26-24-50-50l-297 68L6 1221l259-161v-18q0-9-1-18v-18q0-9 1-18L6 827l159-383l297 68q24-26 50-50l-68-297L827 6l161 259h18q9 0 18-1h18q9 0 18 1L1221 6l383 159l-68 297q26 24 50 50l297-68l159 383l-259 161zm-117 130q2-24 4-47t2-48q0-23-2-47t-4-47l236-147l-86-208l-271 63q-31-38-63-70t-71-64l63-271l-208-86l-148 236q-23-2-47-4t-47-2q-24 0-47 2t-48 4L782 146l-208 86l63 271q-38 31-70 63t-64 71l-271-63l-86 208l236 148q-2 24-4 47t-2 48q0 23 2 47t4 47l-236 147l86 208l271-63q31 38 63 70t71 64l-63 271l208 86l148-236q23 2 47 4t47 2q24 0 47-2t48-4l147 236l208-86l-63-271q38-31 70-63t64-71l271 63l86-208l-236-148zm-642-470q78 0 146 29t120 81t80 119t30 147q0 78-29 146t-81 120t-119 80t-147 30q-78 0-146-29t-120-81t-80-119t-30-147q0-78 29-146t81-120t119-80t147-30zm0 640q55 0 103-20t84-57t56-84t21-103q0-55-20-103t-57-84t-84-56t-103-21q-55 0-103 20t-84 57t-56 84t-21 103q0 55 20 103t57 84t84 56t103 21z"/></svg>
                <span className="ms-1  d-sm-inline"> Settings</span>
              </Link>
            </li> */}
            {/* Get Help */}
            <li>
              <Link href="#" className={`nav-link align-middle px-0`} onClick={handleInterCom}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 14 14">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M3 7V4.37A3.93 3.93 0 0 1 7 .5a3.93 3.93 0 0 1 4 3.87V7M1.5 5.5h1A.5.5 0 0 1 3 6v3a.5.5 0 0 1-.5.5h-1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1m11 4h-1A.5.5 0 0 1 11 9V6a.5.5 0 0 1 .5-.5h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1M9 12.25a2 2 0 0 0 2-2V8m-2 4.25a1.25 1.25 0 0 1-1.25 1.25h-1.5a1.25 1.25 0 0 1 0-2.5h1.5A1.25 1.25 0 0 1 9 12.25" />
                </svg>
                <span className="ms-1  d-sm-inline"> Get Help</span>
              </Link>
            </li>


            {/* <li>
          <a className="cursor-pointer nav-link px-0 align-middle" onClick={handleSignOut}>
            <i className="fs-4 bi-people" />
            <span className="ms-1  d-sm-inline"> Sign Out</span>
          </a>
        </li> */}
            <div className="side-botom">
              <div className="usr">
                {/* <a>
            <img src={photo} alt='user' />
            <span>{userDetails?.person?.first_name} {userDetails?.person?.last_name}</span>
          </a> */}
                <a className="cursor-pointer nav-link px-3 align-middle" onClick={handleSignOut}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22Zm7-6v-3h-8v-2h8V8l5 4l-5 4Z" />
                  </svg>
                  <span className="ms-1  d-sm-inline"> Logout</span>
                </a>
              </div>

              {/* <a className="cursor-pointer nav-link px-3 align-middle" onClick={handleSignOut}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9.5 10.5v2a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v2M6.5 7h7m-2-2l2 2l-2 2" /></svg>
          <span className="ms-1  d-sm-inline"> Sign Out</span>
        </a> */}
            </div>
          </ul>
        </div>
        {/* Logout */}


      </div>
    </>
  );
};

export default Sidebar;
