// apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import endPoints from '../constants';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers) => {
        const user = localStorage.getItem("skyfi");
        const tk = JSON.parse(user);
        if (tk) {
            if (tk) {
                headers.set('Authorization', `Bearer ${tk.access_token}`);
            }
        }
        return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    endpoints: (builder) => ({
        // get sidebar
        getAccounts: builder.query({
            query: () => endPoints.ALL_ACCOUNTS,
        }),
    }),
});

export const {
    useGetAccountsQuery,
} = apiSlice;
