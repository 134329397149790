import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useLocation, useParams } from 'react-router-dom';
import formatDate from '../hooks/formattDate';
import { Bars } from 'react-loader-spinner';
import Pagination from '../components/Pagination';
import useDownload from '../hooks/useDownload';
import { toast } from 'react-toastify';
import { getCurrentDate, getFirstDateOfMonth } from '../hooks/getDates';
import TransactionsHelper from '../hooks/TransactionsHelper.tsx';
import TransactionTile from '../components/TransactionTile.jsx';
import TransactionTable from '../components/TransactionTable.jsx';
import axios from 'axios';
import Sidebar from '../components/Sidebar.jsx';
import SideDefault from '../components/SideDefault.jsx';
import TopPagination from '../components/TopPagination.jsx';

const CardTransaction = () => {
    const location = useLocation();
    const params = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [csvLoading, setCsvLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);

    const [date, setDate] = useState({
        fromDate: null,
        toDate: null
        // fromDate: getFirstDateOfMonth(),
        // toDate: getCurrentDate()
    });

    const [fetchData, setFetchData] = useState(false);
    const [filteredCards, setFilteredCards] = useState(null);
    const isContactId = params?.id?.endsWith('_contact'); // Adjust the logic based on your ID patterns
    const isCardId = params?.id?.endsWith('_card');
    let queryParams = `?&page=${currentPage}&per_page=${perPage}`
    if (params?.acc_id) {
        queryParams += `&account_id=${params?.acc_id?.split("_")[0]}`
    }
    if (isContactId) {
        queryParams += `&contact_id=${params?.id?.split("_")[0]}`
    }
    if (isCardId) {
        queryParams += `&card_id=${params?.id?.split("_")[0]}`
    }

    if (date?.fromDate && date?.toDate) {
        queryParams += `&from=${date?.fromDate}&to=${date?.toDate}`
    }
    useEffect(() => {
        if (fetchData) {
            setFetchData(false);
        }
    }, [fetchData]);

    const [count, setCount] = useState(1);
    const { data: allAccountsData, dataLoading: allAccountsDataLoading } = useFetch(`${endPoints.ALL_ACCOUNTS}/${params?.acc_id?.split("_")[0]}`);

    const { data: cards, dataLoading: cardsLoading } = useFetch(`${endPoints.CARD_TRANSACTION}${queryParams}`, count);
    
    // const { data: csv, dataLoading: cscLoading } = useDownload(`${endPoints.CSV_TRANSACTION_EXPORT}?from=${date?.fromDate}&to=${date?.toDate}&account_id=${params?.acc_id?.split("_")[0]}`, fetchData);
    useEffect(() => {
        setFilteredCards(cards?.data?.data)

    }, [cards]);


    const handleSearch = () => {
        if (!date?.fromDate || !date?.toDate) {
            toast.error("Both Dates Required");
            return;
        }
        setCount(count + 1)
        setCurrentPage(1)
    }
    const handleCsvExport = async () => {
        if (!date?.fromDate || !date?.toDate) {
            toast.error("Both Dates Required");
            return;
        }
        try {
            setCsvLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            // setDataLoading(true);
            const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.CSV_TRANSACTION_EXPORT}?from=${date?.fromDate}&to=${date?.toDate}&account_id=${params?.acc_id?.split("_")[0]}`, {
                headers: {
                    'Authorization': `Bearer ${tk.access_token}`
                }
            });
            if (resp?.status == 200) {

                const link = document.createElement('a');
                link.href = `${resp?.data?.transaction_url}`;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            setCsvLoading(false);
        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data)
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }

    }
    const filterCards = (type) => {
        if (type == "CLEAR") {
            setFilteredCards(cards?.data?.data)
            return
        }
        const filter = cards?.data?.data?.filter((item) => {
            if (item?.attributes?.status == type) {
                return item
            }
        });
        setFilteredCards(filter)
    }

    function formatDateWithWeekday(isoString) {
        const date = new Date(isoString);  // Convert string to Date object
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (zero-indexed)
        const day = String(date.getDate()).padStart(2, '0'); // Get day
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        return `${month}-${day}-${year}`;
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setCount(count + 1);

    };

    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in yyyy-mm-dd format

    return (
        <>
            <MainHeader />

            {!cardsLoading &&
                <div className="s-layout">
                    {/* Sidebar */}
                    <SideDefault />
                    {/* Content */}
                    <main className="s-layout__content busi-secn">
                        <div className="main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap align-items-center d-block pb-4 border-0 hdr-bdr-b">
                                            <div className="me-auto">
                                                <h4 className="text-black fs-26 mb-0">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 14 14">
                                                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M12.5 2.25h-11a1 1 0 0 0-1 1v7.5a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-7.5a1 1 0 0 0-1-1m-12 3.5h13m-4 3.5H11" />
                                                </svg> */}
                                                    Transactions</h4>
                                            </div>
                                            <TopPagination
                                                totalPages={cards?.data?.total_pages}
                                                onPageChange={handlePageChange}
                                                current={currentPage}
                                            />
                                            <div className="export-btn">
                                                <a onClick={handleCsvExport} className='cursor-pointer'>
                                                    {/* {cscLoading ? "Loading" : "Export"} */}
                                                    Export
                                                    <svg style={{ color: "#fff" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M5.552 20.968a2.577 2.577 0 0 1-2.5-2.73c-.012-2.153 0-4.306 0-6.459a.5.5 0 0 1 1 0c0 2.2-.032 4.4 0 6.6c.016 1.107.848 1.589 1.838 1.589h12.463A1.546 1.546 0 0 0 19.825 19a3.023 3.023 0 0 0 .1-1.061v-6.16a.5.5 0 0 1 1 0c0 2.224.085 4.465 0 6.687a2.567 2.567 0 0 1-2.67 2.5Z" />
                                                        <path fill="currentColor" d="M12.337 3.176a.455.455 0 0 0-.311-.138c-.015 0-.028 0-.043-.006s-.027 0-.041.006a.457.457 0 0 0-.312.138L7.961 6.845a.5.5 0 0 0 .707.707l2.816-2.815v10.742a.5.5 0 0 0 1 0V4.737L15.3 7.552a.5.5 0 0 0 .707-.707Z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="search-row dropdown">
                                                <div className="search-dt">
                                                    <input
                                                        max={currentDate}
                                                        value={date?.fromDate}
                                                        onChange={(e) => { setDate({ ...date, fromDate: e.target.value }) }}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="From"
                                                    />
                                                    <input
                                                        max={currentDate}
                                                        value={date?.toDate}
                                                        onChange={(e) => { setDate({ ...date, toDate: e.target.value }) }}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="to"
                                                    />
                                                    <button type="submit" className="" onClick={handleSearch}>
                                                        Search
                                                    </button>
                                                </div>

                                                <button class="filter dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66797 18.0002V6.54346M25.3346 29.457V25.1607M6.66797 29.457V23.7286M25.3346 19.4323V6.54346M16.0013 10.8398V6.54346M16.0013 29.457V16.5681" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                        <path d="M6.66667 23.7284C8.13943 23.7284 9.33333 22.446 9.33333 20.8642C9.33333 19.2823 8.13943 18 6.66667 18C5.19391 18 4 19.2823 4 20.8642C4 22.446 5.19391 23.7284 6.66667 23.7284Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                        <path d="M15.9987 16.5682C17.4715 16.5682 18.6654 15.2859 18.6654 13.704C18.6654 12.1222 17.4715 10.8398 15.9987 10.8398C14.5259 10.8398 13.332 12.1222 13.332 13.704C13.332 15.2859 14.5259 16.5682 15.9987 16.5682Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                        <path d="M25.3346 25.1605C26.8074 25.1605 28.0013 23.8782 28.0013 22.2963C28.0013 20.7145 26.8074 19.4321 25.3346 19.4321C23.8619 19.4321 22.668 20.7145 22.668 22.2963C22.668 23.8782 23.8619 25.1605 25.3346 25.1605Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item cursor-pointer" onClick={() => { filterCards("COMPLETION") }}>Completion</a>
                                                    <a class="dropdown-item cursor-pointer" onClick={() => { filterCards("PENDING") }}>Pending</a>
                                                    <a class="dropdown-item cursor-pointer" onClick={() => { filterCards("CLEAR") }}>Clear Filter</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            {/* <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="search-row dropdown">
                                                        <div className="search-dt">
                                                            <input
                                                                max={currentDate}
                                                                value={date?.fromDate}
                                                                onChange={(e) => { setDate({ ...date, fromDate: e.target.value }) }}
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="From"
                                                            />
                                                            <input
                                                                max={currentDate}
                                                                value={date?.toDate}
                                                                onChange={(e) => { setDate({ ...date, toDate: e.target.value }) }}
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="to"
                                                            />
                                                            <button type="submit" className="" onClick={handleSearch}>
                                                                Search
                                                            </button>
                                                        </div>

                                                        <button class="filter dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.66797 18.0002V6.54346M25.3346 29.457V25.1607M6.66797 29.457V23.7286M25.3346 19.4323V6.54346M16.0013 10.8398V6.54346M16.0013 29.457V16.5681" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                                <path d="M6.66667 23.7284C8.13943 23.7284 9.33333 22.446 9.33333 20.8642C9.33333 19.2823 8.13943 18 6.66667 18C5.19391 18 4 19.2823 4 20.8642C4 22.446 5.19391 23.7284 6.66667 23.7284Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                                <path d="M15.9987 16.5682C17.4715 16.5682 18.6654 15.2859 18.6654 13.704C18.6654 12.1222 17.4715 10.8398 15.9987 10.8398C14.5259 10.8398 13.332 12.1222 13.332 13.704C13.332 15.2859 14.5259 16.5682 15.9987 16.5682Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                                <path d="M25.3346 25.1605C26.8074 25.1605 28.0013 23.8782 28.0013 22.2963C28.0013 20.7145 26.8074 19.4321 25.3346 19.4321C23.8619 19.4321 22.668 20.7145 22.668 22.2963C22.668 23.8782 23.8619 25.1605 25.3346 25.1605Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item cursor-pointer" onClick={() => { filterCards("COMPLETION") }}>Completion</a>
                                                            <a class="dropdown-item cursor-pointer" onClick={() => { filterCards("PENDING") }}>Pending</a>
                                                            <a class="dropdown-item cursor-pointer" onClick={() => { filterCards("CLEAR") }}>Clear Filter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row align-items-center mt-4">
                                                {/* <div className="main-trans">
                                                {/* <h4>Transaction</h4> */}
                                                {/* {filteredCards?.length > 0 && filteredCards?.map((item, id) => {

                                                    let getTransactionType = TransactionsHelper.getTransactionType(item?.attributes)
                                                    let transactionType = getTransactionType + " " + `${getTransactionType == "ACH" ? TransactionsHelper.isDebit(item?.attributes, location?.state?.accountId) ? "OUT" : "IN" : ""}`

                                                    return (
                                                        <TransactionTable txnType={item?.type} item={item} accountId={allAccountsData?.data?.id} />
                                                    )
                                                })
                                                }
                                                {filteredCards?.length == 0 && <p className="">No Data Found</p>}
                                                {filteredCards?.length > 0 && <Pagination totalPages={cards?.total_pages} onPageChange={handlePageChange} current={currentPage} />}
                                            </div>  */}
                                                <div className='transaction-main trns-tbls'>
                                                    <table>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Date</th>
                                                            <th>Type</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                        {filteredCards?.length > 0 && filteredCards?.map((item, id) => (
                                                            <tr key={id}>
                                                                <td>
                                                                    {TransactionsHelper.getLabel(item, allAccountsData?.data?.id) != "No Name" ?
                                                                        TransactionsHelper.getLabel(item, allAccountsData?.data?.id)
                                                                        : item?.attributes?.description}
                                                                </td>
                                                                <td>{formatDateWithWeekday(item?.attributes?.created_at)}</td>
                                                                <td>{item?.attributes?.txn_type}</td>
                                                                {item?.attributes?.debitCredit == "debit" ? <td className='loss'>-${Number(item?.attributes?.amount || 0).toFixed(2)}</td> : <td className='profit'>+${Number(item?.attributes?.amount || 0).toFixed(2)}</td>}
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={cardsLoading}
            />

        </>
    )
}

export default CardTransaction