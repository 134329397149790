import React, { memo, useState } from 'react';

const TopPagination = ({ totalPages, onPageChange, current }) => {

    const [currentPage, setCurrentPage] = useState(current);

    const handlePrevious = (e) => {
        e.preventDefault();
        const newPage = Math.max(currentPage - 1, 1);
        setCurrentPage(newPage);
        onPageChange(newPage);
    };

    const handleNext = (e) => {
        e.preventDefault();
        const newPage = Math.min(currentPage + 1, totalPages);
        setCurrentPage(newPage);
        onPageChange(newPage);
    };

    const handlePageClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const getPageNumbers = () => {
        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(startPage + 2, totalPages);

        if (endPage - startPage < 2) {
            startPage = Math.max(endPage - 2, 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? 'active' : ''}`}
                >
                    <a className="page-link cursor-pointer" onClick={(e) => handlePageClick(e, i)}>
                        {i}
                    </a>
                </li>
            );
        }
        return pageNumbers;
    };
    return (
        <div className="me-auto">
            <div class="prev-next-buttons">
                <button class="prev-button" onClick={handlePrevious} disabled={currentPage === 1}><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14 7l-5 5l5 5" />
                </svg></button>
                <button class="next-button" onClick={handleNext} disabled={current === totalPages}><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m10 17l5-5l-5-5" />
                </svg></button>
            </div>
        </div>
    )
}

export default TopPagination
