import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleMapsComponent from '../components/GoogleMapsComponent';
import MainHeader from '../components/MainHeader';
import endPoints from '../constants';

const ContactAddress = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        if (!params?.acc_id) {
             navigate("/");
        }
    }, [params?.acc_id])
    const [loading, setLoading] = useState(false);

    const [contactAddress, setContactAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        regionCode: "",
        zipCode: "",
    });
   
    const [addressInput, setAddressInput] = useState('');

    const handleChange = (e) => {
        const { value, name } = e.target;
        setContactAddress({ ...contactAddress, [name]: value });

    };

    const handleSelect = async (address) => {
        setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;
           
            const newAddress = {
                address1: address,
                address2: '',
                city: '',
                regionCode: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.regionCode = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
            });

            setContactAddress(newAddress);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (contactAddress?.address1?.trim() == "") {
        //     setErrors(true);
        //     return
        // }
        if (!contactAddress.address1 && location?.state?.data?.wired) {
            toast.error("Contact address is required for wire transfer connection");
            return;
        }


        try {
            setLoading(true);
            let contact = {
                phone: location?.state?.data?.phone,
                account_id: params?.acc_id?.split("_")[0],
                address_type: 1,
                email: location?.state?.data?.email,
                display_name: location?.state?.data?.display_name,
                last_name: location?.state?.data?.last_name,
                first_name: location?.state?.data?.first_name,
                address_1: contactAddress.address1,
                address_2: contactAddress.address2,
                city: contactAddress.city,
                region_code: contactAddress.regionCode,
                zip_code: contactAddress.zipCode,
                wire_routing_number: location?.state?.data?.wire_routing_number,
                country: "US",
                account_type: location?.state?.data?.account_type,
            }
           
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}`, contact,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
               
                navigate(`/view-contacts/${params?.acc_id}`)
                toast.success("contact Created");
                setContactAddress({
                    address1: "",
                    address2: "",
                    city: "",
                    regionCode: "",
                    zipCode: "",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            console.log("Error:", error);
        }
    };

    return (
        <>
            <MainHeader />
            <div className="authincation content-body default-height">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Contact Address {location?.state?.data?.wired ? "*" : ""}
                                                        </h4>
                                                        <form className="mt-4" onSubmit={handleSubmit}>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Address 1</label>
                                                                <GoogleMapsComponent 
                                                                    value={addressInput}
                                                                    onChange={setAddressInput}
                                                                    onSelect={handleSelect}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Address 2</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={2}
                                                                    placeholder="Enter Address 2"
                                                                    spellCheck="false"
                                                                    name="address2"
                                                                    value={contactAddress.address2}
                                                                    onChange={handleChange}
                                                                />

                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">City</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter City"
                                                                    name="city"
                                                                    disabled
                                                                    value={contactAddress.city}
                                                                    onChange={handleChange}
                                                                />
                                                               
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Region Code</label>
                                                                <input
                                                                    type="text"
                                                                    disabled
                                                                    name="regionCode"
                                                                    className="form-control"
                                                                    placeholder="Enter Region Code"
                                                                    value={contactAddress.regionCode}
                                                                    onChange={handleChange}
                                                                />
                                                               
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Zip Code</label>
                                                                <input
                                                                    type="text"
                                                                    name="zipCode"
                                                                    disabled
                                                                    className="form-control"
                                                                    placeholder="Enter Zip Code"
                                                                    value={contactAddress.zipCode}
                                                                    onChange={handleChange}
                                                                />
                                                              
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={loading}>
                                                                    {loading ? "Loading" : "Create"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactAddress;
